import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function TermsNorthAmerica({
  children
}) {
  return <LegalDoc title="Terms of Service - North America" updated="15 Sep 2022">
      {children}
    </LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Terms of Service set out the terms and conditions upon which you may use the Fidel Service and any application or functionality Fidel makes available through the Fidel Service.`}</p>
    <p>{`By signing an Order Form, you agree to and accept the Terms of Service and the Order Form.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Information about Fidel`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Fidel API Service is provided by Enigmatic Smile (`}<strong parentName="li">{`“Fidel API”`}</strong>{`), a company incorporated and registered in England and Wales under company number 08869163 whose registered office is at Swift House Ground Floor, 18 Hoffmanns Way, Chelmsford, Essex, England, CM1 1GU. Fidel API’s VAT number is 247613206.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Interpretation`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In the Terms of Service, save where the context requires otherwise, the following words and expressions have the following meaning:`}
            <ol parentName="li">
              <li parentName="ol">{`“Account” means the Customer’s account on the Fidel Service;`}</li>
              <li parentName="ol">{`“Agreement” means the agreement between the Customer and Fidel comprising the Order Form and the Terms of Service for the provision of the Fidel Service (including the Data Processing Addendum);`}</li>
              <li parentName="ol">{`"American Express" means American Express Travel Related Services Company, Inc. and its affiliated companies;`}</li>
              <li parentName="ol">{`"American Express Crediting Services" means the statement crediting services provided by American Express to Fidel, whereby American Express issues statement credits and debits to the Merchant in relation to Transactions;`}</li>
              <li parentName="ol">{`“API” means Fidel's application programming interface enabling the Customer to connect Users' Payment Cards with loyalty programs via the Customer’s digital applications;`}</li>
              <li parentName="ol">{`"Authorized User" means an employee, worker or other person who is authorized by the Customer to use the Fidel Service on behalf of the Customer;`}</li>
              <li parentName="ol">{`“Authorization Notification” means the provision of a near-real time notification of Transaction Data to the Customer;`}</li>
              <li parentName="ol">{`"Business Day" means a day (other than a Saturday, Sunday or public holiday in England) when banks in London are open for business;`}</li>
              <li parentName="ol">{`“Card Network” means a payment processor that facilitates card payment transactions between issuing banks authorized in accordance with clause 4 to provide Transaction Data to Fidel (for example Visa, Mastercard, or American Express);`}</li>
              <li parentName="ol">{`“Code” means the JavaScript or other source code provided by Fidel to the Customer for incorporation into the Customer’s digital applications;`}</li>
              <li parentName="ol">{`“Commencement Date” means the date from which the Customer will receive the Fidel Service, as set out in the Order Form;`}</li>
              <li parentName="ol">{`“Confidential Information” means information which is identified as confidential or proprietary by either party or by the nature of which is clearly confidential or proprietary;`}</li>
              <li parentName="ol">{`“Customer” means the person identified in the Order Form;`}</li>
              <li parentName="ol">{`“Customer Personal Data” has the meaning as set out in the Data Processing Addendum;`}</li>
              <li parentName="ol">{`“Data Processing Addendum” means the data processing addendum accessible at `}<a parentName="li" {...{
                  "href": "https://fidelapi.com/legal/dpa"
                }}>{`https://fidelapi.com/legal/dpa`}</a>{`;`}</li>
              <li parentName="ol">{`“Documentation” means the description of the Fidel Service functionality and related information available at: `}<a parentName="li" {...{
                  "href": "https://fidelapi.com/docs"
                }}>{`https://fidelapi.com/docs`}</a>{`;`}</li>
              <li parentName="ol">{`“Extended Term” means the period of time set out in the Order Form that the Agreement will automatically extend for following the end of the Initial Term or any Extended Term;`}</li>
              <li parentName="ol">{`“Fees” means the amount set out in the Order Form, or such amount that the parties may agree in writing from time to time, for the provision of the Fidel Service;`}</li>
              <li parentName="ol">{`“Fidel Service” means the Code, the API and any related software Fidel makes available through the Website as a service from time to time and as set out in the Documentation for the purposes of allowing the Customer to connect Users’ Payment Cards with rewards services or card-linked offers in connection with Transactions;`}</li>
              <li parentName="ol">{`“Information Security Incident” means any actual or reasonably suspected:`}
                <ol type="a">
  <li>
    unauthorized use of, or unauthorized access to, any of Customer’s
    computers or networks;
  </li>
  <li>
    damage to, or inability to access, any of Customer’s computers or
    networks due to a malicious use, attack or exploit of such
    computers;
  </li>
  <li>
    unauthorized access to, theft of, or loss of Confidential
    Information, specifically including but not limited to, personal
    data and Transaction Data;
  </li>
  <li>
    unauthorized use of Confidential Information, specifically
    including, but not limited to, personal data and Transaction Data,
    for purposes of actual, reasonably suspected, or attempted theft,
    fraud, identity theft, or other misuse;
  </li>
  <li>
    breach of, or transmission of malicious code or a Virus to, any of
    Customer’s computers or networks arising from, in whole or part,
    an act, error, or omission of Customer, or third parties acting on
    behalf of Customer; or
  </li>
  <li>
    unauthorized disclosure of Confidential Information, specifically
    including, but not limited to, personal data and Transaction Data;
  </li>
                </ol>
              </li>
              <li parentName="ol">{`“Initial Term” means the initial term of the Agreement, commencing on the Commencement Date, as set out in the Order Form;`}</li>
              <li parentName="ol">{`“Mastercard” means Mastercard Europe SA and its affiliated companies;`}</li>
              <li parentName="ol">{`“Merchant” means the person which operates the point of sale equipment relating to a Transaction;`}</li>
              <li parentName="ol">{`“Merchant Portal” means the website operated by Fidel through which Merchants can provide to Fidel information required by Fidel to provide the Fidel Service;`}</li>
              <li parentName="ol">{`“MID” means the Merchant ID;`}</li>
              <li parentName="ol">{`“Minimum Charge Fee” means the minimum monthly fee for use of the Fidel Service, as set out in the Order Form;`}</li>
              <li parentName="ol">{`“On Us Transaction” means a Transaction where the acquiring bank of the Merchant and issuing bank of the Payment Card used by a User to make a Transaction are the same entity, and the Transaction is settled outside of the Card Networks;`}</li>
              <li parentName="ol">{`“Onboarding Fee” means the one-off on-boarding fee payable by the Customer to Fidel, as set out in the Order Form;`}</li>
              <li parentName="ol">{`“Order Form” means the signed (whether electronically or otherwise) order form which amongst other things, identifies the Customer and sets out the Fees;`}</li>
              <li parentName="ol">{`“Payment Card” means a credit or debit card issued by an issuing bank;`}</li>
              <li parentName="ol">{`“Ramp Up Period” means the period specified in the Order Form, or such other period as agreed by the parties in writing;`}</li>
              <li parentName="ol">{`“Settlement Notification” means the provision of non-real time Transaction Data to the Customer relating to cleared Transactions;`}</li>
              <li parentName="ol">{`“Term” means the period of time made up of the Initial Term plus any Extended Term;`}</li>
              <li parentName="ol">{`“Terms of Service” means these terms and conditions of service;`}</li>
              <li parentName="ol">{`“Territory” means the location(s) identified in the Order Form, and such other territories as may be agreed by the parties in writing from time to time;`}</li>
              <li parentName="ol">{`“Tier 1 Support” means first line user support on non-technical issues;`}</li>
              <li parentName="ol">{`“Tier 2 support” means second line support on technical and urgent issues;`}</li>
              <li parentName="ol">{`“Transaction” means a point of sale purchase made by a User in the Territory with a Payment Card enrolled at a loyalty program participating Merchant, excluding any On Us Transaction;`}</li>
              <li parentName="ol">{`“Transaction Data” means data supplied via a Card Network relating to a Transaction including the following: Merchant location, card token, date and time, and amount paid;`}</li>
              <li parentName="ol">{`“User” means an individual who has enrolled their Payment Card(s) with a Customer in order to receive rewards in connection with the Customer’s loyalty programs;`}</li>
              <li parentName="ol">{`“Virus” mean any thing or device (including any software, code, file or program) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any program or data, including the reliability of any program or data (whether by re-arranging, altering or erasing the program or data in whole or part or otherwise); or adversely affect the user experience, including worms, Trojan horses, viruses and other similar things or devices;`}</li>
              <li parentName="ol">{`“Visa” means Visa Europe Services Inc. and its affiliated companies; and`}</li>
              <li parentName="ol">{`“Website” means `}<a parentName="li" {...{
                  "href": "https://fidelapi.com/"
                }}>{`https://fidelapi.com/`}</a>{`.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Duration`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Agreement shall start on the Commencement Date in the Order Form and continue for the Term.`}</li>
          <li parentName="ol">{`The Initial Term of the Agreement shall automatically extend for an Extended Term at the end of the Initial Term, and at the end of each Extended Term thereafter, unless either party gives at least thirty (30) days’ written notice to the other party to terminate the Agreement at the end of the Initial Term or at the end of the then-current Extended Term, as the case may be, such notice to be given no later than thirty (30) days prior to the end of the Initial Term or then-current Extended Term (as applicable).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Merchant's consent to monitor payment activity`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer shall provide to Fidel, or where the Customer is not the Merchant, the Customer shall procure that the Merchant will provide to Fidel through the Merchant Portal, MIDs and data to help Card Networks to identify Merchants, such as Merchant names and branch addresses.`}</li>
          <li parentName="ol">{`The Customer consents, or where the Customer is not the Merchant, shall obtain the consent of the Merchant or shall procure that the Merchant will provide their consent through the Merchant Portal, for the Card Network to monitor Transactions made via the Customer's or Merchant's (as applicable) payment card terminals and to access the corresponding Transaction Data as required by the Fidel Service, for the sole purpose of enabling the Card Network to:`}
            <ol parentName="li">
              <li parentName="ol">{`identify, track and record Transactions; and`}</li>
              <li parentName="ol">{`provide the Transaction Data to Fidel and the Customer.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Where the Customer is not the Merchant, the Customer shall either:`}
            <ol parentName="li">
              <li parentName="ol">{`incorporate a consent agreement provided by Fidel, within any agreement between itself and a Merchant and provide the signed consent agreement to Fidel prior to MIDs being enrolled.; or`}</li>
              <li parentName="ol">{`ensure that the relevant Merchant creates an account on the Merchant Portal in accordance with clause 4.4.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Where the Customer is not the Merchant, the Customer shall procure that each Merchant will create an account on the Merchant Portal prior to the Merchant’s MIDs being enrolled, and will maintain the account for the duration of the Merchant's participation in any relevant loyalty programs with the Customer.`}</li>
          <li parentName="ol">{`Where the Customer is not the Merchant, and where the Customer obtains the information required by the Fidel Service from the Merchant and provides this information to Fidel (and does not, therefore, require the Merchant to create an account on the Merchant Portal), the Customer shall:`}
            <ol parentName="li">
              <li parentName="ol">{`make the following disclosures to the Merchant:`}
                <ol parentName="li">
                  <li parentName="ol">{`description of all information about the Merchant that the Customer collects, including an indication of all potential sources of such data;`}</li>
                  <li parentName="ol">{`identity of the collector of the Merchant's information;`}</li>
                  <li parentName="ol">{`purpose for which the Merchant's information will be used;`}</li>
                  <li parentName="ol">{`to whom the Merchant's information (or portions thereof) may be disclosed, including the recipients or categories of recipients;`}</li>
                  <li parentName="ol">{`whether providing Merchant's information is voluntary or obligatory; and`}</li>
                  <li parentName="ol">{`right of access and right to correct Merchant's information; and`}</li>
                </ol>
              </li>
              <li parentName="ol">{`provide the Merchant with a method to update and correct their information.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`User's consent to monitor payment activity`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`To the extent that the Customer does not utilize the Fidel iFrame as described in the Documentation, the Customer shall obtain affirmative consent from Users and agrees to promptly comply with Fidel's instructions to update disclosures or consent language to enable the Card Network to monitor activity on the User's Payment Card to:`}
            <ol parentName="li">
              <li parentName="ol">{`identify, track and record Transactions; and`}</li>
              <li parentName="ol">{`share such Transaction Data with the Customer in order to enable the User to receive rewards in connection with the Customer's loyalty programs.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The Customer shall include in its disclosures to Users a disclosure regarding American Express Crediting Services and a release of all claims by Users against American Express and its representatives and affiliates, with American Express being a third party beneficiary of such release. American Express is a third party beneficiary entitled to enforce this clause against the Customer.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Customer's restrictions on the use of Transaction Data`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In order to provide the Fidel Service, Fidel will provide the Customer with the Transaction Data.`}</li>
          <li parentName="ol">{`The Customer agrees in respect of the Transaction Data received that it will:`}
            <ol parentName="li">
              <li parentName="ol">{`use the Transaction Data for the sole purpose of enabling the Customer to fulfil rewards to Users in connection with loyalty programs (the “Permitted Use”);`}</li>
              <li parentName="ol">{`retain the Transaction Data only as long as necessary for the Permitted Use and in any event no longer than 7 years from the date of the Transaction;`}</li>
              <li parentName="ol">{`not share, transfer or disclose the Transaction Data to any third parties, or otherwise cause Transaction Data to be disclosed to any third parties (including via assignment) without the prior written consent of Fidel, except that the Customer may share Transaction Data with any subcontractors without the prior written consent of Fidel, solely to the extent necessary for the purpose of performing the Customer’s rights and obligations under this Agreement, and subject to the remaining provisions of this clause. The Customer shall at all times remain liable for all of its obligations under this Agreement, and will be liable for the acts and omissions of any third party (including subcontractors) with which it shares, or to which it gives access to, Transaction Data as if they were the Customer’s own acts and omissions, and will ensure that all such third parties comply with any security obligations of the Customer and any restrictions regarding the use of Transaction Data, as set out in this Agreement;`}</li>
              <li parentName="ol">{`not use the Transaction Data (or any portion thereof) for any marketing purposes. For clarity, administration of loyalty programs in accordance with this Agreement shall not be deemed to violate this restriction; and`}</li>
              <li parentName="ol">{`not use the Transaction Data (or any portion thereof) to develop, derive, or create, directly or indirectly, a personal identification number, individual reference number, or any "customer data integration" system which Customer offers or makes available to third parties.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel has the right to obtain equitable relief regarding the Customer’s use or threatened use of the Transaction Data contrary to the Permitted Use. Where the Card Network providing the Transaction Data is Mastercard, Mastercard shall be a third party beneficiary entitled to enforce this clause against the Customer.`}</li>
          <li parentName="ol">{`At the Customer’s expense, the Customer shall provide to Fidel and to the Card Networks, and to each of their auditors and other advisers and representatives as Fidel may from time to time designate in writing, (giving at least five (5) days' prior written notice) access at all reasonable times (and in the case of regulators, at any time required by such regulators) to any facility in which the Customer performs this Agreement, the Customer's employees, and data and records of the Customer, for the purpose of performing audits and inspections of the Customer in order to verify the Customer's compliance with the terms and conditions of this Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Access to the Fidel Service`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel grants the Customer a non-exclusive, non-transferable, personal and non sub-licensable right in the Territory during the Term to use the Fidel Service in connection with the operation of the Customer’s customer loyalty programs in the Territory.`}</li>
          <li parentName="ol">{`The Customer must treat any username and password to access the Fidel Service or the Account as Confidential Information, and it must not disclose it to any third party.`}</li>
          <li parentName="ol">{`The Customer shall require that each Authorized User keeps secure and confidential their username and password for the Authorized User's use of the Fidel Service via the Customer’s webpages or mobile application and shall not disclose such user name and password to any third party.`}</li>
          <li parentName="ol">{`Fidel may suspend any Account, at any time and at Fidel’s sole discretion, if the Customer has failed, in any material respect, to comply with any of the provisions of this Agreement.`}</li>
          <li parentName="ol">{`The Customer is responsible for maintaining the confidentiality of login details for its Account and any activities that occur under its Account including the activities of Authorized Users. Whenever applicable, Fidel encourages the Customer to use “strong” passwords (passwords that use a combination of upper and lower case letters, numbers and symbols) with its Account. If the Customer has any concerns about the login details for its Account or thinks they have been misused, the Customer should promptly contact Fidel at `}<a parentName="li" {...{
              "href": "mailto:support@fidelapi.com"
            }}>{`support@fidelapi.com`}</a>{`.`}</li>
          <li parentName="ol">{`The Customer must use commercially reasonable efforts to prevent any unauthorized access to, or use of, the Fidel Service and, in the event of any such unauthorized access or use, as soon as reasonably practicable notify Fidel.`}</li>
          <li parentName="ol">{`The Customer recognizes that Fidel is always innovating and finding ways to improve the Fidel Service with new features and services. The Customer acknowledges and agrees that the Fidel Service may change from time to time. FIDEL HEREBY DISCLAIMS ANY WARRANTY, REPRESENTATION OR OTHER COMMITMENT WITH RESPECT TO THE CONTINUITY OF ANY FUNCTIONALITY OF THE FIDEL SERVICE.`}</li>
          <li parentName="ol">{`Fidel undertakes that the Fidel Service will be performed substantially in accordance with the Documentation and with reasonable skill and care.`}</li>
          <li parentName="ol">{`FIDEL LINKS USERS’ PAYMENT CARDS WITH REWARDS SERVICES OR CARD-LINKED OFFERS SOLELY ON BEHALF OF CUSTOMER. FIDEL MAKES NO GUARANTEES, WARRANTIES OR REPRESENTATIONS OF ANY KIND CONCERNING THE REWARDS SERVICES OR CARD-LINKED OFFERS. CUSTOMER HEREBY RELEASES FIDEL FROM ALL LIABILITY ARISING FROM THE REWARDS SERVICES OR CARD-LINKED OFFERS.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Customer's obligations`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel authorizes the Customer to download the Code (including any updates to the Code that Fidel may make available from time to time) from the Website for the sole purpose of integrating the Code into the Customer’s web pages or mobile applications in order to allow Users to connect Payment Cards with the Customer’s customer loyalty programs via the Fidel Service.`}</li>
          <li parentName="ol">{`The Customer shall insert the Code into the Customer’s web pages or mobile applications in accordance with the reasonable directions given by Fidel in writing (including email) from time to time.`}</li>
          <li parentName="ol">{`The Customer shall, on reasonable request from Fidel, update the Code inserted into the Customer’s web pages as soon as reasonably practicable upon receiving notice from Fidel to do the same.`}</li>
          <li parentName="ol">{`The Customer assumes sole responsibility for installation and integration of the Code with its IT systems including but not limited to the Customer's hardware, software, websites and apps.`}</li>
          <li parentName="ol">{`Fidel permits the Customer to use the API (including updates to the API that Fidel may make available from time to time) for the sole purpose of enabling the Customer to connect User’s Payment Cards with loyalty programs via the Customer’s digital applications.`}</li>
          <li parentName="ol">{`The Customer shall use the API in accordance with the reasonable directions given by Fidel in writing (including email) from time to time.`}</li>
          <li parentName="ol">{`The Customer is encouraged to use the most current version of the API made available by Fidel, following reasonable notice from Fidel to do so. Customer acknowledges that the API may only be backwards compatible for a period of two (2) years from the date of such notice.`}</li>
          <li parentName="ol">{`Customer must include the following notice or an equivalent notice (amended as necessary for consistency purposes) in any terms or agreements it has with Users:`}<br /><br />
            <em parentName="li">{`As part of the scheme operated by us, you may be able to enroll your payment card to make it easier to track and earn rewards.  If you choose to enroll your card, your use of this service is via Fidel, our payment data provider, and is subject to Fidel’s End-User Terms of Service which you can view `}<a parentName="em" {...{
                "href": "https://fidelapi.com/legal/end-user-terms-select-transactions-us.pdf"
              }}>{`here`}</a>{`.`}</em></li>
          <li parentName="ol">{`Fidel may monitor the Customer’s use of the Fidel Service to ensure quality, improve the Fidel Service, and verify the Customer’s compliance with the Agreement.`}</li>
          <li parentName="ol">{`The Customer:`}
            <ol parentName="li">
              <li parentName="ol">{`must comply with all applicable laws and regulations with respect to its use of the Fidel Service, the Transaction Data, and its activities under the Agreement;`}</li>
              <li parentName="ol">{`must use the Fidel Service and Transaction Data in accordance with the terms of the Agreement;`}</li>
              <li parentName="ol">{`must obtain and shall maintain all necessary licenses, consents, permits and permissions necessary for Fidel to perform its obligations to the Customer under the terms of the Agreement;`}</li>
              <li parentName="ol">{`must ensure that its network and systems, including its internet browser used complies with the relevant specifications provided by Fidel in writing (including email) from time to time;`}</li>
              <li parentName="ol">{`is solely responsible for procuring and maintaining its network connections and telecommunications links from its systems in order to access and use the Fidel Service;`}</li>
              <li parentName="ol">{`shall not:`}
                <ol type="a">
    <li>
        access, store, distribute or transmit any Virus through the Fidel Service
    </li>
    <li>
        use the Fidel Service to access, store, distribute or transmit any material that is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;
    </li>
    <li>
        use the Fidel Service in a manner that is illegal or causes damage or injury to any person or property;
    </li>
    <li>
        use any automated system, including without limitation "robots," "spiders," "offline readers," to access the Fidel Service in a manner that sends more request messages to the Fidel Service than a human can reasonably produce in the same period of time by using a conventional online web browser, or conduct any denial of service attack on the Fidel Service;
    </li>
    <li>
        attempting to interfere with or compromise the Fidel Service integrity or security. Fidel reserves the right, without liability or prejudice to its other rights under the Agreement, to disable the Customer’s Account for material breaches of the provisions of this clause 8.10.6;
    </li>
                </ol>
              </li>
              <li parentName="ol">{`shall update MIDs provided to Fidel (or, where the Customer is not the Merchant and the Merchant has created an account on the Merchant Portal, the Customer will procure that the Merchant will update MIDs provided to Fidel) promptly after becoming aware of a change and inform Fidel accordingly and consents (or, where applicable, will obtain the Merchant's consent in accordance with clause 4 above) to Fidel providing Card Networks with MIDs and any other data provided by Fidel to Card Networks to help them identify merchants, such as merchant names and branch addresses; and`}</li>
              <li parentName="ol">{`shall promptly notify Fidel in writing if any User unenrolls any of its Payment Cards from any of the Customer's loyalty programs.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Important note on intellectual property rights`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel is the owner of or the licensee of all intellectual property rights in the Fidel Service. These works are protected by copyright and other laws and treaties around the world. All such rights are reserved.`}</li>
          <li parentName="ol">{`The Customer will not, when using the Fidel Service:`}
            <ol parentName="li">
              <li parentName="ol">{`attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Fidel Service in any form or media or by any means; or`}</li>
              <li parentName="ol">{`attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Fidel Service; or`}</li>
              <li parentName="ol">{`access all or any part of the Fidel Service in order to build a product or service which competes with the Fidel Service or use or attempt to use the Fidel Service to directly compete with Fidel.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Publicity, case studies and feedback`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel may use the Customer’s name, logo and related trade marks in any of Fidel’s publicity or marketing materials (whether in printed or electronic form) for the purpose of highlighting that the Customer uses the Fidel Service and alongside any testimonials that the Customer has agreed to give. The Customer grants to Fidel such rights as are necessary to use its name, logo, related trademarks and testimonial for the purposes of this clause 10.1.`}</li>
          <li parentName="ol">{`At the Customer’s written request, Fidel shall cease use of the Customer’s name, logo, or related trade marks in Fidel’s publicity and marketing materials, provided that Fidel shall have no obligation to remove the Customer’s name, logo, and/or related trade marks from any of Fidel’s publicity or marketing materials published (whether in printed or electronic form) prior to the date of Fidel’s receipt of the Customer’s request.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Data protection`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel will process the Customer Personal Data in accordance with the Data Processing Addendum.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Information security`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer shall have in place appropriate technical and organizational measures to keep the Transaction Data secure, and to protect against unauthorized or unlawful disclosure of or access to, or accidental loss or destruction of, or damage to, any Transaction Data.`}</li>
          <li parentName="ol">{`The Customer must promptly notify Fidel in writing upon becoming aware of any actual or suspected Information Security Incident.`}</li>
          <li parentName="ol">{`The Customer must promptly report any Information Security Incident in writing to Fidel (and directly to Mastercard, where applicable), and in any event within twelve (12) hours of confirming such Information Security Incident.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Confidential Information`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Each party may be given access to Confidential Information from the other party in order to perform its obligations under the Agreement. A party's Confidential Information shall not be deemed to include information that:`}
            <ol parentName="li">
              <li parentName="ol">{`is or becomes publicly known other than through any act or omission of the receiving party;`}</li>
              <li parentName="ol">{`was in the other party's lawful possession before the disclosure;`}</li>
              <li parentName="ol">{`is lawfully disclosed to the receiving party by a third party without restriction on disclosure;`}</li>
              <li parentName="ol">{`is independently developed by the receiving party, which independent development can be shown by written evidence; or`}</li>
              <li parentName="ol">{`is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Each party shall hold the other's Confidential Information in confidence and, unless required by law, not make the other's Confidential Information available for use for any purpose other than as needed to perform the terms of the Agreement during the Term and for so long as the Confidential Information remains confidential.`}</li>
          <li parentName="ol">{`Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of the Agreement.`}</li>
          <li parentName="ol">{`Each party shall limit dissemination of Confidential Information of the other party to persons within its own organization; to third parties who are bound by written confidentiality obligations consistent with this clause 13; and all on a “need-to-know” basis.`}</li>
          <li parentName="ol">{`Each party shall use the same degree of care to protect the Confidential Information as it uses to protect its own most highly confidential information, but in no circumstances less than reasonable care. In the event the receiving party receives a court order or other validly issued administrative or judicial process demanding the Confidential Information, or to the extent required to be disclosed by any applicable laws or regulations by a party as a publicly traded company, the receiving party will be permitted to disclose Confidential Information to the minimum extent necessary to comply with such legal obligations. The receiving party will, to the extent not prohibited by law, give the disclosing party prompt written notice of any disclosure that, in the opinion of its counsel, appears to be required by law, so that the disclosing party, at its sole cost and expense, may assert any defenses to disclosure that may be available and seek an injunction or other protective relief.`}</li>
          <li parentName="ol">{`The parties agree that in the event of any breach of the confidentiality obligations described herein, the non-breaching party shall be entitled to seek immediate injunctive relief for such breach in addition to any other right or remedy that the non-breaching party may have at law or in equity.`}</li>
          <li parentName="ol">{`At the time of expiration or termination of this Agreement, as applicable, or upon the other party’s request, each party agrees to promptly return or destroy any Confidential Information provided by the other party in connection with this Agreement.`}</li>
          <li parentName="ol">{`Each party acknowledges and agrees that, as between the parties, Confidential Information disclosed by the disclosing party to the receiving party will at all times remain the property of the disclosing party. No license under any trade secrets, copyrights, or other rights is granted by any disclosure of Confidential Information under this Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Price and payment`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Subject to clause 14.3, the Customer shall pay the Fees to Fidel monthly in arrears.`}</li>
          <li parentName="ol">{`For the purpose of calculating the Fees, the provision of an Authorization Notification shall be considered one Transaction and the provision of a Settlement shall be considered a second, separate Transaction. Each of the two Transactions will be subject to separate Fees.`}</li>
          <li parentName="ol">{`The Customer shall pay the Minimum Charge Fee for the first month of the Term, together with the Onboarding Fee, no later than 2 working days from the Commencement Date. Thereafter, the Minimum Charge Fee for each month during the Term shall be payable monthly in advance.`}</li>
          <li parentName="ol">{`Fidel shall invoice the Customer for the Fees at the end of each month during the Term. Customer shall pay any Invoice within seven (7) Business Days from the receipt of the Invoice.`}</li>
          <li parentName="ol">{`Unless alternative payment is agreed in the Order Form, the Customer will provide to Fidel valid, up-to-date and complete credit or debit card details and it hereby authorizes Fidel to bill such credit or debit card for the Fees in accordance with the Order Form.`}</li>
          <li parentName="ol">{`If Fidel has not received full payment of an undisputed invoice within 30 days after the due date, and without prejudice to any other rights and remedies available to Fidel:`}
            <ol parentName="li">
              <li parentName="ol">{`Fidel may, without liability to the Customer, suspend or temporarily disable all or part of its access to the Fidel Service and Fidel shall be under no obligation to provide any access to the Fidel Service while the invoice(s) concerned remain unpaid, but shall reinstate such access at no further cost when the delinquent invoice has been paid; and`}</li>
              <li parentName="ol">{`interest shall accrue each day on such due amounts at an annual rate equal to 3% over the then current base lending rate of Barclays Bank PLC at the date the relevant invoice was issued, commencing on the due date and continuing until fully paid, whether before or after judgment.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`All amounts and Fees stated or referred to in the Agreement:`}
            <ol parentName="li">
              <li parentName="ol">{`are payable in the currency specified in the Order Form or otherwise stipulated by Fidel; and`}</li>
              <li parentName="ol">{`are exclusive of any taxes (including sales tax) unless otherwise expressly stated which shall be paid at the same time as payment of the Fees. Fidel shall send the Customer a tax invoice if Fidel is requested to do so.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Unless otherwise agreed between the parties in writing, Fidel may increase any of the Fees at any time after the Initial Term upon giving at least sixty (60) days’ notice (the “Notice Period”) in writing to the Customer, such increase to take effect on expiry of such Notice Period. If the Customer is unhappy with the increase, the Customer may give notice to terminate the Agreement by providing at least thirty (30) days' written notice to Fidel, and the Agreement will terminate at the end of the Notice Period. The Fees will not increase during the Notice Period.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Availability and support`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Where the Customer has paid for access to the Fidel Service, Fidel will use commercially reasonable efforts to make the Fidel Service available with an uptime rate of 99.99% except for:`}
            <ol parentName="li">
              <li parentName="ol">{`planned maintenance for which 24 hours’ notice will be given; or`}</li>
              <li parentName="ol">{`unscheduled maintenance during normal business hours (UK time) or otherwise, for which Fidel will use reasonable efforts to give the Customer advance notice; or`}</li>
              <li parentName="ol">{`any downtime caused by or arising from the services (or any non-availability of the services) provided by the Card Networks.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The Customer will provide Users with “Tier 1 support” during normal business hours of the Customer. Fidel will provide the Customer with Tier 2 support to enable the Customer to provide Tier 1 support to the Users. The Customer may raise Tier 1 or Tier 2 support tickets with Fidel via email. Fidel shall respond to email support queries no later than 24 hours after receipt.`}</li>
          <li parentName="ol">{`Fidel agrees that Transactions via any of the Card Networks will track and report no later than five (5) Business Days after they are made.`}</li>
          <li parentName="ol">{`Fidel agrees that MIDs provided by the Customer shall take no longer than twenty (20) Business Days to process for Transactions of any Card Network to begin tracking.`}</li>
          <li parentName="ol">{`The Customer acknowledges that the Fidel Service is dependent on access to various third party data sources (including the Transaction Data). The Customer agrees that Fidel is not responsible for the non-availability or interruption to the Fidel Service caused by any such non-availability of any such third party data source unless such non-availability or interruption is caused by Fidel acting negligently.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Suspension and termination`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`If the Customer fails to pay any sum due to Fidel and such sum remains outstanding for a further thirty (30) days following notice requiring such sum to be paid, Fidel may terminate the Agreement with the Customer immediately by notice in writing and without any liability for Fidel to the Customer.`}</li>
          <li parentName="ol">{`Fidel may terminate the Agreement by written notice with immediate effect if the Customer infringes Fidel’s intellectual property rights in the Fidel Service.`}</li>
          <li parentName="ol">{`Either party may terminate the Agreement at any time on written notice to the other if:`}
            <ol parentName="li">
              <li parentName="ol">{`the other party is in material breach of any of the terms of the Agreement and either that breach is incapable of remedy, or the other party fails to remedy that breach within 30 days after receiving written notice requiring it to remedy that breach;`}</li>
              <li parentName="ol">{`the other party is in breach of applicable law; or`}</li>
              <li parentName="ol">{`one of the following events of or relating to insolvency occurs:`}
                <ol parentName="li">
                  <li parentName="ol">{`the other party commences a judicial or administrative proceeding under a law relating to insolvency for the purpose of reorganizing or liquidating the debtor or restructuring its debt;`}</li>
                  <li parentName="ol">{`anyone commences any such proceeding against the other party and either (A) the proceeding is not dismissed by midnight at the end of the 60th day after commencement or (B) any court before which the proceeding is pending issues an order approving the case; a receiver, trustee, administrator, or liquidator (however each is referred to) is appointed or authorized, by law or under a contract, to take charge of property of the party for the purpose of enforcing a lien against that property, or for the purpose of general administration of that property for the benefit of the party’s creditors;`}</li>
                  <li parentName="ol">{`the other party makes a general assignment for the benefit of creditors; or`}</li>
                  <li parentName="ol">{`the other party generally fails to pay its debts as they become due (unless those debts are subject to a good-faith dispute as to liability or amount) or acknowledges in writing that it is unable to do so.`}</li>
                </ol>
              </li>
            </ol>
          </li>
          <li parentName="ol">{`Upon termination of the Agreement for any reason all licenses granted under the Agreement shall immediately terminate and the Customer's right to access and use the Fidel Service will end.`}</li>
          <li parentName="ol">{`If the Agreement is terminated by Fidel in accordance with clause 16.3, the Customer shall pay Fidel any shortfall between the total aggregate Minimum Charge Fee for the duration of the Term and any Fees already paid to Fidel at the date of termination together with all other amounts payable to Fidel, within thirty (30) days of the date of termination of the Agreement.`}</li>
          <li parentName="ol">{`The accrued rights of the parties as at termination, or the continuation after termination of any provision expressly stated to survive or implicitly surviving termination shall not be affected or prejudiced.`}</li>
          <li parentName="ol">{`In the event that a Card Network is unwilling or unable to provide Transaction Data to Fidel or the Customer, Fidel shall continue to provide the Fidel Service only in respect of the remaining Card Networks. Fidel may terminate the Agreement by written notice with immediate effect in the event that all Card Networks are unwilling or unable to provide the Transaction Data to Fidel or the Customer. If this clause is exercised, Fidel agrees to provide the Customer with written proof that the relevant Card Network(s) is unwilling or unable to provide the Transaction Data.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Limited warranty; Disclaimer`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE FIDEL SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND FIDEL MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, OR FOR ACTS OR OMISSIONS RELATED TO THIRD PARTY PROVIDERS, THIRD PARTY SOFTWARE (INCLUDING OPEN SOURCE SOFTWARE) OR THIRD PARTY MATERIALS. FIDEL FURTHER DISCLAIMS ANY WARRANTY THAT THE OPERATION OF THE FIDEL SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.`}</li>
          <li parentName="ol">{`Except as expressly and specifically provided for in the Agreement:`}
            <ol parentName="li">
              <li parentName="ol">{`the Customer assumes sole responsibility for (i) installation and integration of the Fidel Service with its IT systems including but not limited to the Customer's hardware, software, websites and apps; and (ii) any results obtained from the use of the Fidel Service and for any conclusions drawn or taken from such use and it relies on the results obtained from the Fidel Service at its own risk;`}</li>
              <li parentName="ol">{`all representations, warranties, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by law, excluded from the Agreement; and`}</li>
              <li parentName="ol">{`Fidel will not be responsible for any interruptions, delays, failures or non-availability affecting the Fidel Service or the performance of the Fidel Service which are caused by third party services or errors or bugs in software, hardware or the internet on which Fidel relies to provide the Fidel Service and the Customer acknowledges that Fidel does not control such third party services and that such errors and bugs are inherent in the use of such software, hardware and the Internet.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`SOME STATES DO NOT ALLOW EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Liability`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`SUBJECT TO CLAUSE 18.2, NEITHER PARTY WILL BE LIABLE TO THE OTHER WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR BREACH OF STATUTORY DUTY), MISREPRESENTATION, RESTITUTION OR OTHERWISE FOR ANY LOSS OF INCOME OR REVENUE; LOSS OF BUSINESS; LOSS OF PROFITS; LOSS OF ANTICIPATED SAVINGS; LOSS OF DATA (OTHER THAN LOSS OF DATA CAUSED BY A BREACH OF THE AGREEMENT); OR ANY INDIRECT, CONSEQUENTIAL OR SPECIAL DAMAGES, COSTS OR EXPENSES.`}</li>
          <li parentName="ol">{`NOTHING IN THE AGREEMENT EXCLUDES OR LIMITS EITHER PARTY’S LIABILITY FOR GROSS NEGLIGENCE OR FOR FRAUD OR FRAUDULENT MISREPRESENTATION OR FOR ANY INDEMNITY GIVEN BY EITHER PARTY IN ACCORDANCE WITH CLAUSE 19.`}</li>
          <li parentName="ol">{`SUBJECT TO CLAUSE 18.2 AND OTHER THAN AS EXPRESSLY STATED OTHERWISE IN THIS AGREEMENT, EACH PARTY’S TOTAL LIABILITY IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR BREACH OF STATUTORY DUTY), MISREPRESENTATION, RESTITUTION OR OTHERWISE ARISING IN CONNECTION WITH THE PERFORMANCE OR CONTEMPLATED PERFORMANCE OF THE AGREEMENT SHALL IN ALL CIRCUMSTANCES BE LIMITED TO AN AMOUNT EQUAL TO THE FEES PAID OR PAYABLE BY THE CUSTOMER IN THE FIRST TWELVE (12) MONTHS OF THE TERM.`}</li>
          <li parentName="ol">{`SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Indemnity`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer shall indemnify and defend Fidel, the Card Networks, and each of their officers, directors, employees, agents and contractors from and against any and all losses, damages, claims, liabilities or expenses (including reasonable costs and attorneys’ fees) arising out of any claim brought by a third party relating to the Customer's use of the Fidel Service (except to the extent caused by Fidel's negligence and other than as set out in clause 19.2), provided that Fidel:`}
            <ol parentName="li">
              <li parentName="ol">{`gives the Customer prompt notice of any such claim;`}</li>
              <li parentName="ol">{`provides reasonable cooperation to the Customer in the defense and settlement of such claim, at the Customer's expense; and`}</li>
              <li parentName="ol">{`gives the Customer sole authority to defend or settle the claim`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel shall indemnify and defend the Customer, its agents and employees against any and all losses, damages, claims, liabilities or expenses (including reasonable lawyers' fees) arising out of a claim brought by a third party alleging that the use of the Fidel Service in accordance with this Agreement infringes the intellectual property rights of the third party provided that the Customer:`}
            <ol parentName="li">
              <li parentName="ol">{`gives Fidel prompt notice of any such claim;`}</li>
              <li parentName="ol">{`provides reasonable cooperation to Fidel in the defense and settlement of such claim, at Fidel's expense; and`}</li>
              <li parentName="ol">{`gives Fidel sole authority to defend or settle the claim`}</li>
            </ol>
          </li>
          <li parentName="ol">{`In no event shall Fidel, its employees, agents and sub-contractors be liable to the Customer to the extent that the alleged infringement is based on:`}
            <ol parentName="li">
              <li parentName="ol">{`a modification of the Fidel Service by anyone other than Fidel or anyone acting on behalf of Fidel;`}</li>
              <li parentName="ol">{`the Customer's use of the Fidel Service in a manner contrary to this Agreement; or`}</li>
              <li parentName="ol">{`the Customer's use of the Fidel Service after notice of the alleged or actual infringement from Fidel or any appropriate authority has been given to the Customer.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Written communications`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Applicable laws may require that some of the information or communications Fidel sends to the Customer should be in writing. When using the Fidel Service, the Customer accepts that communication with Fidel will be mainly electronic. Fidel will contact the Customer by e-mail or provide the Customer with information by posting notices on the Fidel Service. For contractual purposes, the Customer agrees to this electronic means of communication and the Customer acknowledges and agrees that all contracts, notices, information and other communications that Fidel provides to the Customer electronically comply with any legal requirement that such communications be in writing.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Notices`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`All notices given by the Customer to Fidel must be given to `}<a parentName="li" {...{
              "href": "mailto:cs@fidelapi.com."
            }}>{`cs@fidelapi.com.`}</a>{` Fidel may give notice to the Customer at either the e-mail or postal address the Customer provides to Fidel, or any other way Fidel deems appropriate. Notice will be deemed received and properly served immediately when posted on the Fidel Service or 24 hours after an e-mail is sent or three days after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an e-mail that such e-mail was sent to the specified e-mail address of the addressee.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Transfer of rights and obligations`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer may not transfer, assign, charge or otherwise deal in the Agreement, or any of its rights or obligations arising under the Agreement, without Fidel’s prior written consent.`}</li>
          <li parentName="ol">{`Fidel may transfer, assign, charge, or otherwise deal in the Agreement, or any of its rights or obligations arising under the Agreement, in Fidel’s sole discretion without the Customer’s prior written consent. If Customer sells all or a substantial part of its business or materially changes the nature of its business, or by any means causes or permits a new entity to own a substantial part of its business, Customer agrees to notify Fidel in advance.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Changes to the Agreement`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel may amend these Terms of Service from time to time. Every time the Customer uses the Fidel Service, the Customer acknowledges that it is responsible for checking these Terms of Service to ensure it understands the Terms of Service that apply at any time. Any changes to these Terms of Service in the future will be posted on this page and, where appropriate, notified to the Customer by e-mail.`}</li>
          <li parentName="ol">{`Subject to clause 14.10, no variation of the Order Form shall be effective unless it is in writing and agreed by the parties (or their authorized representatives).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Events outside a party's control`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`No party shall be liable to the other for any delay or non-performance of its obligations under the Agreement arising from any cause beyond its control including, without limitation, any of the following: telecommunications failure, internet failure, act of God, governmental act, war, fire, flood, explosion or civil commotion.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Waiver`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`No forbearance or delay by either party in enforcing its rights shall prejudice or restrict the rights of that party, and no waiver of any such rights or of any breach of any contractual terms shall be deemed to be a waiver of any other right or of any later breach.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Severability`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`If any provision of the Agreement is judged to be illegal or unenforceable, the continuation in full force and effect of the remainder of the provisions shall not be prejudiced.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Third party rights`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Except as otherwise expressly provided in this Agreement, no provisions of this Agreement are intended nor shall be interpreted to provide or create any third-party beneficiary rights or any other rights of any kind in any other party.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Law and jurisdiction`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Agreement may not be amended without the prior written consent of both parties, and shall be governed by and interpreted in accordance with the laws of the state of New York (without reference to conflict of law principles) in the city of New York and county of New York. The Customer hereby consents to jurisdiction in New York, New York for all claims arising out of or relating to the Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Counterparts`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`This Agreement may be executed in counterparts, each of which shall be deemed an original and all of which together shall constitute one and the same instrument. This Agreement may be executed by facsimile or .pdf signature, either of which shall constitute an original for all purposes.`}</li>
        </ol>
      </li>
    </ol>
    <h2><strong parentName="h2">{`Data Processing Addendum`}</strong></h2>
    <ol {...{
      "start": 30
    }}>
      <li parentName="ol"><strong parentName="li">{`BACKGROUND`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In accordance with clause 11 of the Agreement, this Data Processing Addendum ("DPA")
sets out the basis on which the parties Process Customer Personal Data (as defined below).`}</li>
          <li parentName="ol">{`In the event of a conflict between any of the provisions of this DPA and the remaining
provisions of the Agreement, the provisions of this DPA shall prevail.`}</li>
          <li parentName="ol">{`Both parties will comply with all applicable requirements of the Data Protection Laws (as
defined below). This DPA is in addition to, and does not relieve, remove or replace, a party's
obligations under the Data Protection Laws.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DEFINITIONS`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Unless otherwise set out below, each capitalized term in this DPA shall have the meaning set out in clause 2 of the Agreement, and the following capitalized terms used in this DPA shall be defined as follows:`}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`"Customer Personal Data"`}</strong>{` means the `}<strong parentName="li">{`“personal data”`}</strong>{` or `}<strong parentName="li">{`“personal information”`}</strong>{` (as defined in the Data Protection Laws) of Users supplied by Customer for the purposes of Fidel providing the Fidel Service;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Data Protection Laws”`}</strong>{` means all laws relating to the use, protection and privacy of Customer Personal Data (including, without limitation, the privacy of electronic communications) which are from time to time applicable to the Customer, Fidel or the Fidel Service;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Security Incident"`}</strong>{` means any accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, any Customer Personal Data; and`}</li>
              <li parentName="ol"><strong parentName="li">{`"Sub-processor"`}</strong>{` means any processor engaged by Fidel that agrees to receive from Fidel and any Customer Personal Data.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DATA PROCESSING`}</strong>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Instructions for Data processing.`}</strong>{` Fidel will only process Customer Personal Data in accordance with (a) the Agreement, to the extent necessary to provide the Fidel Service, and (b) the Customer's written instructions, unless processing is required by applicable laws to which Fidel is subject, in which case Fidel shall, to the extent permitted by such law, inform the Customer of that legal requirement before processing that Customer Personal Data.`}</li>
          <li parentName="ol">{`The Agreement (subject to any changes to the Fidel Service agreed between the Parties), including this DPA, shall be the Customer's complete and final instructions to Fidel in relation to the processing of Customer Personal Data.`}</li>
          <li parentName="ol">{`Processing outside the scope of this DPA or the Agreement will require prior written agreement between the Customer and Fidel on additional instructions for processing.`}</li>
          <li parentName="ol"><strong parentName="li">{`Required consents and disclosures.`}</strong>{` Where required by applicable Data Protection Laws, the Customer will ensure that it has obtained or will obtain all necessary consents, and has provided appropriate disclosures and notices, for the processing of Customer Personal Data by Fidel and the Card Networks in accordance with the Agreement, including consent from Users to enable the Card Networks to collect, process and share Customer Personal Data relating to the Users for the purposes set out in the Agreement (including this DPA).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`TRANSFER OF PERSONAL DATA`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Authorized Sub-processors. The Customer agrees that Fidel may use `}{`[Amazon Web Services, Inc.]`}{` as a Sub-processor to process Customer Personal Data.`}</li>
          <li parentName="ol">{`The Customer agrees that Fidel may use subcontractors to fulfil its contractual obligations under the Agreement. Fidel shall notify the Customer from time to time of the identity of any Sub-processor it engages. If the Customer (acting reasonably) does not approve of a new Sub-processor, then the Customer may request that Fidel moves the Customer Personal Data to another Sub-processor and Fidel shall, within a reasonable time following receipt of such request, use all reasonable efforts to ensure that the Sub-processor does not process any of the Customer Personal Data.`}</li>
          <li parentName="ol">{`Fidel shall use reasonable efforts to ensure that it enters into a written agreement with any Sub-processors which imposes materially the same obligations on the Sub-processor with regards to their processing of Customer Personal Data as are imposed on Fidel under this DPA.`}</li>
          <li parentName="ol"><strong parentName="li">{`Liability of Sub-processors.`}</strong>{` Fidel shall at all times remain responsible for compliance with its obligations under the DPA and will be liable to the Customer for the acts and omissions of any Sub-processor approved by the Customer as if they were the acts and omissions of Fidel.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DATA SECURITY, AUDITS AND SECURITY NOTIFICATIONS`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel Security Obligations. Fidel shall implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including the measures set out in ANNEX 1.`}</li>
          <li parentName="ol">{`Security Audits. The Customer may, upon reasonable notice, audit (by itself or using independent third party auditors) Fidel’s compliance with the security measures set out in this DPA (including the technical and organizational measures as set out in ANNEX 1), including by conducting audits of Fidel’s data processing facilities. Upon request by the Customer, Fidel shall make available all information reasonably necessary to demonstrate compliance with this DPA.`}</li>
          <li parentName="ol">{`Security Incident Notification. If Fidel or any Sub-processor becomes aware of a Security Incident, Fidel will (a) notify the Customer of the Security Incident within 72 hours, (b) investigate the Security Incident and provide such reasonable assistance to the Customer (and any law enforcement or regulatory official) as required to investigate the Security Incident, and (c) take steps to remedy any non-compliance with this DPA.`}</li>
          <li parentName="ol"><strong parentName="li">{`Fidel Employees and Personnel.`}</strong>{` Fidel shall treat the Customer Personal Data as the Confidential Information of the Customer, and shall ensure that:`}
            <ol parentName="li">
              <li parentName="ol">{`access to Customer Personal Data is limited to those employees or other personnel who have a business need to have access to such Customer Personal Data; and`}</li>
              <li parentName="ol">{`any employees or other personnel have agreed in writing to protect the confidentiality and security of Customer Personal Data.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`INDIVIDUALS’ RIGHTS`}</strong>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Requests from individuals.`}</strong>{` Save as required (or where prohibited) under applicable law, Fidel shall notify the Customer of any request received by Fidel or any Sub-processor from an individual to exercise their rights in respect of their personal data included in the Customer Personal Data under Data Protection Laws (“`}<strong parentName="li">{`Individual Requests`}</strong>{`”), and shall not respond to any such request.`}</li>
          <li parentName="ol">{`Fidel shall use reasonable efforts to assist the Customer to fulfil the Customer’s obligation to respond to Individual Requests.`}</li>
          <li parentName="ol"><strong parentName="li">{`Government Disclosure`}</strong>{`. Fidel shall notify the Customer of any request for the disclosure of Customer Personal Data by a governmental or regulatory body or law enforcement authority (including any data protection supervisory authority), unless otherwise prohibited by law or a legally binding order of such body or agency.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`TERMINATION`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Deletion of data. Subject to clauses 7.2 and 7.3 below, Fidel shall, within ninety (90) days of the date of termination of the Agreement:`}
            <ol parentName="li">
              <li parentName="ol">{`return a complete copy of all Customer Personal Data by secure file transfer in such a format as notified by the Customer to Fidel; and`}</li>
              <li parentName="ol">{`delete and use all reasonable efforts to procure the deletion of all other copies of Customer Personal Data processed by Fidel or any Sub-processors.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Subject to clause 7.3 below, the Customer may in its absolute discretion notify Fidel in writing within thirty (30) days of the date of termination of the Agreement to require Fidel to delete and procure the deletion of all copies of Customer Personal Data processed by Fidel. Fidel shall, within ninety (90) days of the date of termination of the Agreement:`}
            <ol parentName="li">
              <li parentName="ol">{`comply with any such written request; and`}</li>
              <li parentName="ol">{`use all reasonable efforts to procure that its Sub-processors delete all Customer Personal Data processed by such Sub-processors, and, where this clause 7.2 applies, Fidel shall not be required to provide a copy of the Customer Personal Data to the Customer.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel and its Sub-processors may retain Customer Personal Data to the extent required by applicable laws and only to the extent and for such period as required by applicable laws and always provided that Fidel shall ensure the confidentiality of all such Customer Personal Data and shall ensure that such Customer Personal Data is only processed as necessary for the purpose(s) specified in the applicable laws requiring its storage and for no other purpose.`}</li>
        </ol>
      </li>
    </ol>
    <h2><strong parentName="h2">{`Annex 1`}</strong></h2>
    <h3>{`TECHNICAL AND ORGANIZATIONAL SECURITY MEASURES`}</h3>
    <ol>
      <li parentName="ol">{`Fidel maintains internal policies and procedures, or procures that its Subprocessors do so,
which are designed to:`}
        <ol parentName="li">
          <li parentName="ol">{`secure any Customer Personal Data Processed by Fidel against accidental or unlawful loss, access or disclosure;`}</li>
          <li parentName="ol">{`identify reasonably foreseeable and internal risks to security and unauthorized access to the Customer Personal Data Processed by Fidel;`}</li>
          <li parentName="ol">{`minimize security risks, including through risk assessment and regular testing.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Fidel will, and will use reasonable efforts to procure that its Subprocessors do, conduct
periodic reviews of the security of its network and the adequacy of its information security
program as measured against industry security standards and its policies and procedures.`}</li>
      <li parentName="ol">{`Fidel will, and will use reasonable efforts to procure that its Subprocessors periodically do,
evaluate the security of its network and associated services to determine whether additional
or different security measures are required to respond to new security risks or findings
generated by the periodic reviews.`}</li>
      <li parentName="ol">{`Fidel is Level 1 PCI compliant.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      